import React from "react";
import PropTypes from "prop-types";
// gatsby
import { Link } from "gatsby";

export default function Breadcrumb({ items }) {
  return (
    <div>
      <nav className="breadcrumb">
        <ul>
          {items.map((item) => {
            return (
              <li key={item.id}>
                <Link to={item.url}>{item.label}</Link>
                <span className="separator">»</span>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}

Breadcrumb.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};
