/** @jsx jsx */
import { jsx, Input, Flex, Button } from "theme-ui";
import { useState, useCallback } from "react";
import { selectorSrcmProfile } from "gatsby-plugin-hfn-profile/state/selectors";
import { useSelector } from "react-redux";
import Layout from "../layouts/Layout";
import Breadcrumb from "../components/Breadcrumb";
import { useFetchSrcmApi } from "../../../gatsby-plugin-hfn-profile/fetch-srcm-api";

const Rsearch = () => {
  const [regNumber, setRegNumber] = useState("");
  const [error, setError] = useState("No Data to display.");
  const { fetchSrcmApi } = useFetchSrcmApi();
  const srcmProfile = useSelector(selectorSrcmProfile);
  const [apiResponse, setApiResponse] = useState([]);

  const breadCrumbs = [
    {
      id: 1,
      label: "Home",
      url: "/",
    },
    {
      id: 2,
      label: "Registration Search",
      url: `/registration-search`,
    },
  ];

  // const btnStyle = {
  //   p: "8px",
  //   borderRadius: "4px",
  //   background: "#1ca0f2",
  //   color: "#fff",
  //   cursor: "pointer",
  // };

  // const cancelReg = () => {
  //   const requestApi = {
  //     ...apiResponse,
  //     ref: "B99999999",
  //     status: "cancelled",
  //     partner_id: null,
  //     city_id: apiResponse.city_id.id,
  //   };
  //   fetchSrcmApi({
  //     api: `/api/v2/event-registrations/${apiResponse.id}/`,
  //     method: "PUT",
  //     client: "eventsClient",
  //     methodParams: requestApi,
  //   }).then((datax) => {
  //     console.log(datax);
  //   });
  // };

  const doValidateAndSubmit = useCallback(() => {
    if (regNumber.length === 12) {
      fetchSrcmApi({
        api: `/api/v2/event-registrations/`,
        client: "eventsClient",
        methodParams: { pnr: regNumber },
      }).then((data) => {
        setApiResponse(data.results);
        if (!data.results[0])
          setError("Entered Registration number is invalid");
      });
    }
  }, [fetchSrcmApi, regNumber]);

  return (
    <Layout
      pageName="events-list-page"
      headerTitle="Events Registration Search"
      sidebarVisible
    >
      {srcmProfile?.name ? (
        <div sx={{ maxWidth: "800px", margin: "0 auto" }}>
          <Breadcrumb items={breadCrumbs} />
          <Flex sx={{ gap: "10px", mb: "20px" }}>
            <Input
              type="text"
              placeholder="Enter Registration Number"
              value={regNumber}
              onChange={(e) => setRegNumber(e.target.value)}
            />
            <Input
              sx={{
                bg: "#1ca0f2",
                color: "#fff",
                cursor: "pointer",
                width: "250px",
              }}
              type="submit"
              value="Search"
              onClick={() => doValidateAndSubmit()}
            />
          </Flex>
          {apiResponse[0]?.name ? (
            <div>
              {/* {!updateReg ? ( */}
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <span>PNR: {regNumber}</span>
                  </h4>
                </div>
                <div className="card-body">
                  <Flex sx={{ variant: "RegDetails", mb: 2 }}>
                    <h4>Contact Name</h4>
                    <h4>Venue City</h4>
                    <h4>Status</h4>
                  </Flex>
                  {apiResponse.map((item) => (
                    <Flex sx={{ variant: "RegDetails", mb: 1 }}>
                      <p>{item.name ? item.name : "-"}</p>
                      <p>{item.city_id ? item.city_id.name : "-"}</p>
                      <p
                        className={`${
                          item.status === "confirmed"
                            ? "badge-green"
                            : "badge-red"
                        }`}
                      >
                        {item.status ? item.status : "-"}
                      </p>
                    </Flex>
                  ))}
                  <Button
                    sx={{
                      bg: "#1ca0f2",
                      color: "#fff",
                      cursor: "pointer",
                      width: "150px",
                      mt: 2,
                    }}
                  >
                    View Details
                  </Button>
                  {/* <Flex sx={{ justifyContent: "flex-end", mt: "6px" }}>
                      <Flex sx={{ gap: "6px" }}>
                        <button
                          type="button"
                          sx={{
                            ...btnStyle,
                          }}
                          onClick={() => setUpdateReg(true)}
                        >
                          Update Registration
                        </button>
                        <button
                          type="button"
                          sx={{
                            ...btnStyle,
                            background: "#474747",
                          }}
                          onClick={() => cancelReg()}
                        >
                          Cancel Registration
                        </button>
                      </Flex>
                    </Flex> */}
                </div>
              </div>
              {/* ) : (
                 <UpdateRegistration
                   data={apiResponse}
                   setUpdateReg={setUpdateReg}
                   renderPnr={doValidateAndSubmit}
                 />
               )} */}
            </div>
          ) : (
            <div>{error}</div>
          )}
        </div>
      ) : (
        <p>Sign in to search by Registration number</p>
      )}
    </Layout>
  );
};

export default Rsearch;
